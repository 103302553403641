import Vue from 'vue'
import App from './App.vue'
// 引入ElementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/zh-CN'
import moment from "moment"
import 'moment/locale/zh-cn'
import router from './router'
Vue.prototype.$moment = moment
import JsonExcel from 'vue-json-excel'

Vue.component('downloadExcel', JsonExcel)
Vue.config.productionTip = false
// 设置ElementUI语言为中文, 组件大小为mini
Vue.use(ElementUI, {
  locale: locale,
  size: 'mini'
})

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
